<template>
    <div>
        <section class="hero is-medium is-dark has-background">
            <img :src="`https://swissdominoeffect.com/img/headers/${header_image}`" class="is-transparent hero-background" />
            <div class="hero-body">
                <div class="container">
                    <h1 class="title is-1 is-size-2-mobile">
                        Dominos an Schulen
                    </h1>
                </div>
            </div>
        </section>

        <section class="hero is-small is-orange">
            <div class="hero-body">
                <div class="container">
                    <p class="title">
                        SDE @School Portal

                        <a href="https://swissdominoeffect.com/schulen/portal" target="_blank"
                        class="button is-medium is-white is-outlined"
                        title="Hier klicken um zum Portal zu gelangen">Schnellzugriff Portal</a>
                    </p>
                    
                </div>
            </div>
        </section>

        <section class="section">
            <div class="container">
                <div class="content is-medium">
                    <p class="subtitle is-3">
                        Dominosteine eignen sich ideal für Schulprojekte. Sämtliche unserer Angebote für Schulen sind
                        angelehnt an die Kompetenzvoraussetzungen des aktuellen Lehrplan 21.
                    </p>

                    <div class="notification is-danger is-size-4">
                        <strong> <b-icon icon="camera-plus" size="is-medium"></b-icon> &nbsp; Klassenwettbewerb </strong><br>
                        Bis zum 31. Mai 2024 haben Sie die Möglichkeit, uns Fotos Ihres Dominoprojekts zu schicken und an unserer Verlosung teilzunehmen. 
                        Gewinnen Sie als Schulklasse einen Preis von CHF 200.- für Ihre Klassenkasse!
                    </div>

                    <p class="is-size-4">
                        Das gemeinsame Aufbauen fördert nicht nur die Konzentrationsfähigkeit, sondern auch den
                        Gruppenzusammenhalt und die Kommunikation untereinander. Des Weiteren lernen die Schülerinnen
                        und Schüler:<br>
                        <ul>
                            <li>Eigene Vorstellungen und Ideen gestaltend umzusetzen <sup>1</sup></li>
                            <li>Objekte in einem Plan darzustellen <sup>2</sup></li>
                            <li>Technische Zusammenhänge spielerisch zu erkunden <sup>3</sup></li>
                            <li>Gestalterische und technische Produkte zu planen und zu realisieren <sup>4</sup></li>
                        </ul>
                        Diese fachlichen und überfachliche Kompetenzen sind äusserst wichtig und sind für jede
                        Altersstufe hilfreich. Nebst unseren Vermietungs- und Workshopsangeboten, bieten wir auch unser
                        <b>SDE @School Portal</b> an. Darauf finden Sie diverse Anleitungen, Tricks und Unterlagen,
                        welche Sie
                        für Ihren ganz eigenen Domino Day buchen können. Weitere Infos dazu finden Sie weiter unten.
                    </p>
                    <p class="is-6">
                        <span>
                            <sup>1 </sup><a
                                href="https://v-ef.lehrplan.ch/index.php?code=a|7|2|2|1|3&hilit=101b2aRJbhggFyX4JPy9SRyt5zt8VvPwv#101b2aRJbhggFyX4JPy9SRyt5zt8VvPwv"
                                target="_blank" rel="nofollow">TTG.2.A.3</a>
                        </span>
                        <br>
                        <span>
                            <sup>2 </sup><a
                                href="https://v-ef.lehrplan.ch/index.php?code=a|5|0|2|3|4&hilit=101ZcLugrvR4kHBeA26rpSsfnkA64ETDU#101ZcLugrvR4kHBeA26rpSsfnkA64ETDU"
                                target="_blank" rel="nofollow">MA.2.C.4.c</a>
                        </span>
                        <br>
                        <span>
                            <sup>3 </sup><a
                                href="https://zh.lehrplan.ch/index.php?code=a|7|2|1|1|1&hilit=101kkHYDeXeAp4f3aqYKRzAnSpkvJtabN"
                                target="_blank" rel="nofollow">TTG.1.A.1.a</a>
                        </span>
                        <br>
                        <span>
                            <sup>4 </sup><a
                                href="https://v-ef.lehrplan.ch/index.php?code=a|5|0|2|3|4&hilit=101ZcLugrvR4kHBeA26rpSsfnkA64ETDU#101ZcLugrvR4kHBeA26rpSsfnkA64ETDU"
                                target="_blank" rel="nofollow">MA.2.C.4.e</a>
                        </span>

                    </p>

                    <Impressionen title="Gemeinsames Aufbauen von Konstruktionen verbindet" :content="pictures" />

                    <!--<Impressionen title="Unendliche Möglichkeiten mit Kettenreaktionen" :content="videos" /> -->
                    <hr>
                    <h1>Erfahrungen und Rückmeldungen</h1>
                    <b-carousel :indicator="true" :indicator-background="false" :indicator-inside="true"
                        indicator-mode="hover" indicator-position="is-bottom" indicator-style="is-lines"
                        :interval="9000">
                        <b-carousel-item v-for="(rating, i) in ratings" :key="i">
                            <section :class="`hero is-small is-light`">
                                <div class="hero-body has-text-centered">
                                    <p class="is-size-4"><i v-html="rating.text"></i><br><b>{{rating.author}}</b></p>
                                </div>
                            </section>
                        </b-carousel-item>
                    </b-carousel>
                    <hr>

                    <PortalAd :get_access_action="access_action" />

                    <div class="tile is-ancestor">
                        <div class="tile is-vertical is-7">
                            <div class="tile is-tablet-12">
                                <div class="tile is-parent">
                                    <article class="tile is-child notification is-success">
                                        <p class="title">Projekttage mit Dominos</p>
                                        <p class="subtitle">Jetzt online Kosten berechnen und tausende Dominosteine
                                            mieten.</p>
                                        <router-link class="button" :to="{name: 'mieten'}">Zum Kostenrechner
                                        </router-link>
                                    </article>
                                </div>
                                <div class="tile is-parent">
                                    <article class="tile is-child notification is-primary">
                                        <div class="content">
                                            <p class="title">Online-Shop</p>
                                            <p class="subtitle">Profitieren Sie als Schule beim Kauf von Dominosteinen.
                                            </p>
                                            <router-link class="button" :to="{name: 'shop'}">Zum Online-Shop
                                            </router-link>
                                        </div>
                                    </article>
                                </div>
                            </div>
                            <div class="tile is-parent">
                                <article class="tile is-child notification is-danger">
                                    <p class="title">Eigene Ideen?</p>
                                    <p class="subtitle">Für individuelle Anfragen stehen wir jederzeit zu Verfügung.</p>
                                    <router-link class="button" :to="{name: 'kontakt'}">Zum Kontaktformular
                                    </router-link>
                                </article>
                            </div>
                        </div>
                        <div class="tile is-parent is-tablet-5">
                            <article class="notification is-warning">
                                <div v-if="is_beratung_submitted">
                                    <p class="title">Vielen Dank!</p>
                                    <p class="subtitle">Wir haben Ihre Beratungsanfrage erhalten und werden uns
                                        entsprechend mit Ihnen telefonisch in Verbindung setzen.</p>
                                </div>
                                <div v-else>
                                    <p class="title">Beratung</p>
                                    <p class="subtitle">Gerne beraten wir Sie bei nächster Gelegenheit telefonisch.</p>
                                    <b-field label="Für Sie passender Beratungstermin*">
                                        <b-select placeholder="Beratungstermin" v-model="beratung.time"
                                            validation-message="Bitte wählen Sie eine Option aus." required>
                                            <option value="asap">Schnellstmöglich</option>
                                            <option value="vormittags">Vormittags</option>
                                            <option value="nachmittags">Nachmittags</option>
                                            <option value="abends">Abends (nach 18 Uhr)</option>
                                        </b-select>
                                    </b-field>
                                    <b-field label="Name*">
                                        <b-input v-model="beratung.name"
                                            validation-message="Bitte geben Sie einen Namen an." required></b-input>
                                    </b-field>
                                    <b-field label="Telefonnummer*">
                                        <b-input v-model="beratung.telephone" placeholder="Bspw. 079 800 20 20"
                                            validation-message="Bitte geben Sie eine gültige Telefonnummer an."
                                            required>
                                        </b-input>
                                    </b-field>
                                    <p class="is-size-7">* Pflichtfelder</p>
                                    <b-button type="is-primary" @click="handleSubmitBeratung"
                                        :loading="is_beratung_loading" class="is-size-5 is-size-6-mobile">Telefonische
                                        Beratung
                                        vereinbaren</b-button>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<style scoped>
.is-orange {
    background-color: orange;
    color: white;
}
.is-orange .title {
    color: white;
}
</style>
<script>
    import {
        submitTelefonBeratung
    } from '@/webapi.js'
    import Impressionen from '@/components/Impressionen'
    import PortalAd from '@/components/PortalAd'
    export default {
        metaInfo: {
            title: 'Angebot für Schulen'
        },
        data: function () {
            return {
                header_image: 'schule_domino_banner.jpg',
                activeStep: 0,
                pictures: [],
                videos: [
                    'https://www.youtube.com/embed/VTf-xcQNMcU',
                    'https://www.youtube.com/embed/r6pPKzRtyb4',
                    'https://www.youtube.com/embed/KjoknOnOyOg',
                    'https://www.youtube.com/embed/FZ5eQpH-A7Q',
                    'https://www.youtube.com/embed/PMzeibvJbXI',
                    'https://www.youtube.com/embed/GfatQdBeDkY',
                    'https://www.youtube.com/embed/J1ugTaJejuE',
                    'https://www.youtube.com/embed/9c0guyJEm3o'
                ],
                ratings: [{
                        color: 'white',
                        text: 'Der Domino-Workshop hat den Primarschülern und Lehrpersonen total Spass gemacht. Die Kids waren friedlich und kooperativ, verzichteten auf die Pause und wollten gar nicht mehr aufhören zu bauen. Die Kommunikation und Lieferung der Steine funktionierten tipptopp. Wenig Aufwand, viel Ertrag :) Danke!',
                        author: 'Claudia (Schule Waldstatt)'
                    },
                    {
                        color: 'white',
                        text: 'Swiss Domino Effect  kann ich nur wärmstens empfehlen. Die Organisation der Übergabe und Rückgabe der Steine verlief tadellos und zuverlässig. Meine Klasse hatte eine tolle Woche, und die Auswertung ergab nur „Gut" und „Sehr gut". Die Website mit ihren Anleitungen, Filmen und Tipps und Tricks ist professionell aufgemacht und bietet alles, was man zu einer erfolgreichen Domino-Week braucht. Sie lässt keine Wünsche offen und wurde die ganze Woche von Schülern und Lehrern intensiv benutzt. 10 von 10 Punkten.',
                        author: 'Walter Dux (Schule Eselriet Effretikon)'
                    },
                    {
                        color: 'info',
                        text: 'Es war ein mega Event bei uns und für die Kids eine echte super spassige und herausfordernde Aufgabe mit soo vielen Lerneffekten. Auch viele Erwachsene waren begeistert, die es bei uns verfolgt haben.',
                        author: 'Kindergarten Oberfeld'
                    },
                    {
                        color: 'success',
                        text: 'Das Arbeiten mit den Dominosteinen war, wahrhaftig ein tolles Erlebnis.<br>Wir hatten alle sehr viel Spass daran und würden es Jedem weiter empfehlen.',
                        author: 'Romy W., Leonora S., Emira R. (Schülerinnen)'
                    },
                    {
                        color: 'info',
                        text: 'Die ganze Miete (Bestellung, Abholung und Rückgabe) erfolgte sehr unkompliziert. Der Anlass mit den Dominosteinen war ein Erfolg und hat uns viel Spass gemacht!',
                        author: 'Lukas (Kantonsschule Alpenquai)'
                    },
                    {
                        color: 'success',
                        text: 'Wir haben einen Sponsorenevent organisiert und Daniel sowie Jonas haben uns mit Ihrem Fachwissen dabei bestens unterstützt. Zudem klappte alles tiptop mit der Lieferung der Unmenge an Dominosteinen für den Anlass. Es war ein tolles Erlebnis, vielen Dank euch beiden!',
                        author: 'Rudolf Steiner Schule Zürich'
                    },
                    {
                        color: 'info',
                        text: 'War einfach top. Die Steine kamen pünktlich an und wurden auch wieder sauber abgeholt. Uns ging eine Kiste kapput wobei hier alles professionell geklärt wurde und gleich eine neue Kiste mitgenommen wurde. Was auch top ist. Obwohl die Fahrt weit war und einiges gekostet hat, war es trotzdem günstiger als lokale Anbieter wo wir die Steine auch hätten holen müssen.',
                        author: 'Matthias (Cevi Steinmaur-neerach-stadel)'
                    },
                    {
                        color: 'primary',
                        text: 'Wir waren mit eurem Service, euren Aktionen, eurem Einsatz, eurer Dienstleistung<br> und eurer unkomplizierten Art mehr als zufrieden!',
                        author: 'Barbara (Primarschule Kreuzgut)'
                    }
                ],
                beratung: {
                    name: '',
                    time: '',
                    telephone: ''
                },
                is_beratung_submitted: false,
                is_beratung_loading: false
            }
        },
        components: {
            Impressionen,
            PortalAd
        },
        created() {
            let directory = require.context('@/assets/img/schule/', true)
            directory.keys().forEach(element => {
                this.pictures.push(directory(element))
            })
        },
        methods: {
            handleSubmitBeratung() {
                if (!this.beratung.time || !this.beratung.name || !this.beratung.telephone) {
                    return
                }
                this.is_beratung_loading = true
                submitTelefonBeratung(this.beratung).then(resp => {
                    this.is_beratung_submitted = resp.data.state
                }).catch(error => {
                    console.error(error)
                    alert(
                        'Leider ist ein Fehler aufgetreten. Ihr Termin konnte nicht an uns weitergeleitet werden.'
                    )
                }).finally(() => this.is_beratung_loading = false)
            },
            access_action() {
                this.$router.push({
                    name: 'mieten'
                })
            }
        }
    }
</script>